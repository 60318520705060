import React from "react";
import {WorkHero} from "../components/workwithus/workHero";


export default function WorkWithUs() {
  return (
    <div className="max-w-[1920px] mx-auto overflow-hidden bg-white">
    <WorkHero/>
    
    </div>
  );
}
